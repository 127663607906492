import React from "react";
import { Helmet } from "react-helmet";
import Logo from "../components/Logo/Logo";
import Link from "../components/Link/Link";
import Button from "../components/Button/Button";
import Newsletter from "../components/Newsletter/Newsletter";
import SocialLinks from "../components/Social/SocialLinks";
import WattpadBooks from "../components/OtherBooks/WattpadBooks";
// import TipLinks from "../components/Social/TipLinks";
import "./links.scss";

export default function Links() {
  return (
    <main className="quick-links centered u-mar-top-lg u-mar-btm-lg">
      <Helmet title="Yvette Russell | Quick Links" defer={false} />
      <Link url="/" type="image" className="u-mar-btm-sm">
        <Logo className="quick-links__logo-icon" />
      </Link>
      <h1 className="quick-links__heading u-mar-btm-xs">Yvette Russell</h1>
      <p className="quick-links__subheading u-mar-btm-lg">Author &amp; Illustrator</p>
      <div className="quick-links__button-list">

      {/*
      // <h3 className="quick-links__cta-heading u-mar-btm-md">Christmas Books</h3>
      //   <Button
      //     url="https://www.wattpad.com/story/355166245-christmas-debate" 
      //     target="_blank"
      //     type="christmas"
      //     className="quick-links__button"
      //   >
      //     <strong>Christmas Debate</strong> on Wattpad
      //   </Button>
      //   <Button
      //     url="https://www.wattpad.com/story/313042078-christmas-break" 
      //     target="_blank"
      //     type="christmas"
      //     className="quick-links__button"
      //   >
      //     <strong>Christmas Break</strong> on Wattpad
      //   </Button>
      //   <Button
      //     url="https://www.wattpad.com/story/170245159-reindeer-crossing"
      //     target="_blank"
      //     type="christmas"
      //     className="quick-links__button"
      //   >
      //     <strong>Reindeer Crossing</strong> on Wattpad
      //   </Button>
      */}

      <h3 className="quick-links__cta-heading u-mar-btm-md u-mar-top-xl">My Books</h3>
        <WattpadBooks 
          count="3"
          className="u-mar-btm-md"
        />
        <Button
          url="https://www.yvetterussell.com/books"
          target="_blank"
          type="primary"
          className="quick-links__button"
        >
          All my Books
        </Button>
        <Button
          url="https://wattpad.com/yvetterussell"
          target="_blank"
          className="quick-links__button"
        >
          Read on Wattpad
        </Button>

      <h3 className="quick-links__cta-heading u-mar-top-xl u-mar-btm-md">Subscribe to my Newsletter</h3>
        <Newsletter type="links" className="u-mar-btm-lg" />

      <h3 className="quick-links__cta-heading u-mar-top-xl u-mar-btm-md">Connect with me</h3>
        <Button
          url="https://wattpad.com/yvetterussell"
          target="_blank"
          rel="noreferrer" 
          className="quick-links__button"
        >
          Find me on Wattpad
        </Button>
        {/*
        // <Button
        //   url="https://www.patreon.com/yvetterussell"
        //   target="_blank"
        //   rel="noreferrer" 
        //   className="quick-links__button"
        // >
        //   Follow Me on Patreon
        // </Button>
        // <Button
        //   url="https://buymeacoffee.com/yvetterussell"
        //   target="_blank"
        //   rel="noreferrer" 
        //   className="quick-links__button"
        // >
        //   Buy me a Coffee
        // </Button>
        */}
        <Button
          url="https://ko-fi.com/yvetterussell"
          target="_blank"
          rel="noreferrer" 
          className="quick-links__button"
        >
          Tip Jar on Ko-Fi
        </Button>
        <Button
          url="/"
          target="_blank"
          className="quick-links__button"
        >
          yvetterussell.com
        </Button>
      <SocialLinks 
        size="large" 
        className="centered u-mar-top-md u-mar-btm-lg"
      />
      </div>
    </main>
  );
}

import React from "react";
import { StaticQuery, graphql } from "gatsby";
import BookThumb from "../Book/BookThumb";
import "./OtherBooks.scss";

export default function WattpadBooks(props) {
  return (
    <div
      className={
        "other-books other-books--wattpad" +
        (props.type ? "other-books--" + props.type : "") + " " +
        (props.className ? props.className : "")
      }
    >
      { props.type === "stinger" ? 
        <div className="other-books__intro">
          <h3 className="book-cta__heading other-books__heading u-mar-btm-sm">
            <strong>More Books</strong> by <strong>Yvette Russell</strong>
          </h3>
        </div> 
      : ""}
      <div className="other-books__wrapper">
        <ul className="other-books__books">
          <StaticQuery
            query={
              graphql`
                query wattpadList {
                  posts: allMarkdownRemark(
                    sort: { order: DESC, fields: [frontmatter___date] }
                    limit: 3
                  ) {
                    edges {
                      node {
                        id
                        frontmatter {
                          date(formatString: "MMMM DD, YYYY")
                          linkWattpad
                          title
                          bookCover {
                            childImageSharp {
                              gatsbyImageData(
                                width: 200
                                placeholder: BLURRED
                                formats: [AUTO, WEBP, AVIF]
                                quality: 100
                              )
                            }
                          }
                        }
                      }
                    }
                  }
                }
              `
            }
            render={(data) => {
              const Books = data.posts.edges.filter(
                (edge) => !!edge.node.frontmatter.date
              );

              return Books.map((edge) => (
                <li key={edge.node.id} className="other-books__book">
                  <BookThumb
                    type="stripped"
                    target="_blank"
                    link={edge.node.frontmatter.linkWattpad}
                    image={edge.node.frontmatter.bookCover}
                    title={edge.node.frontmatter.title}
                  />
                </li>
              ));
            }}
          />
        </ul>
      </div>
    </div>
  );
}
